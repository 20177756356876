import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  LRestaurantPickup,
  LRestaurantPlan,
  CHeroImg,
  CDefinition,
  LRestaurant,
  CPanelCard,
  LReservation,
  CLocalNav,
  CBtnList,
  CSectTitle,
  CPickup,
  ShopContents,
  CHeroMedia,
  CRestaurantsHead,
  RestaurantNav,
  LRestaurantContact,
} from '../../../components/_index';
import pickupGet from '../../../utils/pickup-get';
import { pickupRestaurantsChoice } from '../../../utils/pickup-choice';
import shopGet from '../../../utils/shop-get';
import { shopFixedChoice } from '../../../utils/shop-choice';
import restaurantsGet from '../../../utils/restaurants-get';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: 'TAKEOUT',
            sub: 'テイクアウト',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/takeout/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/takeout/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'レストラン&バー',
                path: '/restaurants/',
              },
            ],
            current: {
              label: 'テイクアウト',
            },
          }}
        />
      </CJumbotron>
      <div className="l_sect02">
        <LWrap>
          <CLocalNav
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/private/img_localnav05.png',
                  alt: '',
                },
                link: {
                  href: '#coffret',
                },
                title: {
                  main: 'コフレ',
                  sub: '1Fデリカ＆ラウンジ',
                },
              },
            ]}
          />
        </LWrap>
      </div>
      <section className="u_mbLarge" id="coffret">
        <LWrap>
          <ShopContents
            data={shopFixedChoice(shopGet(), 'coffret_takeout').contents1}
          />
          <CBtnList
            data={[
              {
                label: 'その他メニュー・店舗詳細',
                link: {
                  href: '/restaurants/coffret/',
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="u_mbLarge">
        <LWrap>
          <RestaurantNav />
        </LWrap>
      </section>
      <section>
        <LRestaurant />
      </section>
      <section className="l_sect">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
            <li>料金には消費税、サービス料が含まれます。</li>
            <li>料理の表示価格は、特別な記載がない限り1名様分の料金です。</li>
            <li>
              テイクアウト商品の料金には消費税（8％）のみ含まれます。コフレ店内をご利用の場合は、サービス料を加算、消費税は10％となります。
            </li>
            <li>入荷状況によりメニュー内容に変更がある場合がございます。</li>
            <li>
              「神奈川県公共的施設における受動喫煙防止条例」に基づきレストラン・バーを全席禁煙とさせていただいております。
            </li>
            <li>
              ご入店の際は、タンクトップ・短パン・ビーチサンダルなどはご遠慮いただいております。
            </li>
          </ul>
        </LWrap>
      </section>
      <LRestaurantContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
